.containerWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  &:first-child {
    margin-left: 0;
  }

  .title {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .digitContainer {
    display: flex;
    flex-direction: row;
    padding: 0;

    .singleDigit {
      position: relative;
      display: flex;
      flex: 0 1 25%;
      font-size: 30px;
      background-color: #404549;
      border-radius: 5px;
      padding: 10px 12px;
      color: white;
      &:first-child {
        margin-right: 2px;
      }
      &:after {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 50%;
        bottom: 50%;
        content: '';
        width: '100%';
        height: 2px;
        background-color: #232323;
        opacity: 0.4;
      }
    }
  }
}
