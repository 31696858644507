.wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  z-index: 10;
  background-image: linear-gradient(180deg, rgba(66, 65, 65, 0.8), transparent);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;

  a {
    img {
      max-width: 150px;
      max-height: 150px;
    }
    @media (max-width: 768px) {
      padding: 0 5px;
    }
  }

  .nav-menu {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin: 0 5px;

    a {
      display: flex;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      text-decoration: none;
      color: white;

      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }

      span {
        font-size: 13px;
        letter-spacing: 1.4px;
        position: relative;
        padding: 0 8px;

        &:after {
          //make the hover effect on the navbar menu
          content: '';
          height: 2px;
          background: white;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -6px;
          opacity: 0;
          transform-origin: left center;
          transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
          transform: scaleX(0);
        }
      }
      &:hover {
        //make the hover effect on the navbar menu
        span:after {
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }
  }
}

.fullscreen-btn {
  background-color: transparent;
  border: none;
  color: white;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}
